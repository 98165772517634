.e_ob {
  contain: content;
  overflow: hidden;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80%;
  background-color: rgb(var(--g-90));
}

.e_pb {
  min-height: 7.5rem;
}

.e_qb {
  min-height: 15.625rem;
}

@media (min-width: 64rem) {

.e_pb {
    min-height: 15.625rem
}
}

.e_hc {
  width: 100%;
}

  .e_hc {
  --container-width: var(--wrapper-inner-width);
}

  /* variables */

  @media (min-width: 64rem) {.e_hc {
    max-width: 51.25rem;
    margin-left: auto;
    margin-right: auto
}.e_hc {
    --container-width: 51.25rem
}
}

.e_q {
  margin-top: 1.25rem;
}

.e_r {
  margin: 1.25rem 0;
}

.e__h {
  display: flex;
  row-gap: 2.5rem;
  position: relative;
  flex-direction: column;
}

  .e__h .e_0h {
    height: 100vh;
  }

  .e__h .e_1h {
    position: sticky;
    top: 4rem;
  }

.e_0b {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.e_tc {
  top: var(--top);
  z-index: 8;
  transition: transform 0.2s;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  transform: translateY(var(--translate-complex));

  --top: 3.125rem;
  --translate: 0rem;
  --translate2: 0rem;
  --translate-complex: calc(var(--translate) + var(--translate2));
}

  .e_tc.e_uc {
    --translate2: 1.875rem;
  }

  .e_tc.e_vc:not(.e_wc) {
    --translate: 0rem;
  }

  .e_tc:not(.e_xc) {
    --translate: 1.875rem;
  }

  .e_tc.e_xc {
    --translate: -0.625rem;
  }

  .e_tc:not(.e_yc) {
    --translate: 0rem;
  }

  .e_tc.e_zc {
    --top: 5.125rem;
  }

  @media (min-width: 64rem) {.e_tc {
    --top: 4.375rem
}

    .e_tc.e_zc {
      --top: 8.25rem;
    }

    .e_tc.e_Ac:not(.e_xc) {
      --translate: 1.875rem !important;
    }
}

  @media (min-width: 80rem) {

  .e_tc.e_uc {
      --translate2: 0
  }
    .e_tc.e_xc {
      --translate: 1.875rem;
    }
}

.e_2m,
.e_3m {
  display: none;
}

.e_3m {
  --container-width: 0;
}

/* variables */

.e_2m {
  --container-width: 0;
}

/* variables */

.e_4m {
  /* variables */
  --container-width: var(--wrapper-inner-width);
}

@media (min-width: 64rem) {.e_5m {
    display: grid;
    grid-column-gap: 1.875rem;
    -moz-column-gap: 1.875rem;
         column-gap: 1.875rem;
    grid-template-columns: 1fr 18.75rem
}

.e_3m {
    display: block
}

.e_3m {
    --container-width: 18.75rem
}

.e_4m {
    --container-width: calc(var(--wrapper-inner-width) - 20.625rem)
}
}

@media (min-width: 80rem) {.e_5m {
    display: grid;
    grid-template-columns: minmax(0, 270fr) minmax(0, 660fr) 18.75rem
}

.e_2m {
    display: block
}

.e_2m {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - 20.625rem) / 960) * 270)
}

.e_4m {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - 20.625rem) / 960) * 660)
}
}

.e_2c {
  display: none;
}

.e_2c {
  --container-width: 0;
}

/* variables */

.e_3c {
  /* variables */
  --container-width: var(--wrapper-inner-width);
}

@media (min-width: 64rem) {.e_4c {
    display: grid;
    grid-gap: 1.875rem 6.25rem;
    gap: 1.875rem 6.25rem;
    grid-template-columns: minmax(0, 1fr) 18.75rem
}

.e_2c {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    row-gap: 1.25rem
}

.e_2c {
    --container-width: 18.75rem
}

.e_3c {
    --container-width: calc(var(--wrapper-inner-width) - 25rem)
}
}

.e_ic {
  display: flex;
  -moz-column-gap: 0.375rem;
       column-gap: 0.375rem;
  align-items: center;
  flex-wrap: wrap;
}

.e_jc {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: -0.00625rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.3125rem 0;
  color: rgb(var(--p-40));
}

.e_kc {
  color: rgb(var(--g-50));
}

.e_lc {
  width: 1rem;
  height: 1rem;
  color: rgb(var(--p-40));
}

.e_qc {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.e_rc {
  color: rgb(var(--g-25));
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.01875rem;
}

.e_sc {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.e_mc {
  min-height: 27.5rem;
}

.e_vn {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

  .e_vn:hover .e_wn {
      opacity: 1;
      pointer-events: all;
    }

  .e_vn:not(:last-of-type)::after {
    margin-right: 0.3125rem;
    content: ',';
  }

.e_wn {
  top: 100%;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100vw;

  transition: opacity 0.2s;
}

.e_vn:focus-within ~ .e_xn {
  opacity: 1;
  pointer-events: all;
}

.e_xn {
  opacity: 0;
  pointer-events: none;
  background-color: rgba(var(--b), 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;

  transition: opacity 0.2s;
}

@media (min-width: 40rem) {

.e_wn {
    width: 20.625rem
}

.e_xn {
    display: none
}
}

.e_jn {
  position: relative;
}

.e_kn {
  padding: 0.25rem 0.25rem 0.25rem 0;
}

.e_ln {
  color: rgb(var(--g-25));
  width: 1rem;
  height: 1.25rem;
}

.e_mn {
  width: auto;
  border: 0.0625rem solid rgba(var(--b), 0.05);
  transform: scale(0.55) translateY(-0.125rem);
  background-color: rgb(var(--w));
  padding: 0.25rem;
  top: 100%;
  transition: opacity 0.2s, transform 0.2s;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transform-origin: 0 0;
  left: 0;
}

.e_mn .vf-share-bar-square {
    width: auto !important;
  }

.e_nn {
  transform: scale(0.6) translateY(0);
  opacity: 1;
  pointer-events: auto;
}

@media (min-width: 40rem) {

.e_mn {
    transform: scale(0.55) translateY(-0.125rem) translateX(-50%)
}

.e_nn {
    transform: scale(0.6) translateY(0) translateX(-50%)
}
}

.e_Rc {
  display: flex;
  row-gap: 0.25rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  flex-direction: column;
}

.e_Sc {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.e_Tc {
  color: rgb(var(--p-40));
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.00625rem;
}

.e_Uc {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgb(var(--g-25));
  letter-spacing: -0.00625rem;
}

.e_Vc {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgb(var(--g-65));
  letter-spacing: -0.00625rem;
}

.e_Wc {
  display: none;
}

.e_Xc {
  height: 1.25rem;
}

.e_Yc {
  color: rgb(var(--g-25));
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.e_Zc {
  padding-left: 0.625rem;
}

.e__c {
  display: none;
}

@media (min-width: 40rem) {.e_Rc {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center
}

.e__c {
    display: block
}
}

@media (min-width: 64rem) {

.e_Zc {
    padding-left: 1.875rem
}
}

.e_nc {
  gap: 1.25rem;
}

.e_oc {
  gap: 0.3125rem;
  display: flex;
  flex-wrap: wrap;
}

.e_pc {
  padding: 0.3125rem 0;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: rgb(var(--g-40));
}

.e_pc:hover {
    text-decoration: underline;
  }

.e_pc:not(:last-child)::after {
    content: ',';
  }

.e_0c {
  font-size: 2rem;
  line-height: 2.375rem;
  font-weight: 600;
  letter-spacing: -0.00625rem;
}

.e_1c {
  font-size: 1rem;
  word-break: break-word;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.00625rem;
  color: rgb(var(--g-25));
}

@media (min-width: 64rem) {.e_0c {
    font-size: 2.5rem;
    line-height: 2.875rem
}

.e_1c {
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: -0.03125rem
}
}

.e_Lc {
  margin-top: 0.5rem;
}

.e_dd {
  display: grid;
  grid-gap: 0.1875rem;
  gap: 0.1875rem;
}

.e_ed {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
}

.e_fd {
  width: 3.8125rem;
  height: 0.5rem;
  stroke: rgb(var(--p-70));
  fill: transparent;
  margin: 0.3125rem 0;
}

.e_gd {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  overflow-wrap: anywhere;
}

.e_gd:not(.e_hd) {
    opacity: 0.6;
    transition: opacity 0.2s;
  }

.e_gd:hover {
    opacity: 1;
  }

.e_gd.e_hd {
    display: flex;
    align-items: center;
    -moz-column-gap: 0.625rem;
         column-gap: 0.625rem;
  }

.e_id {
  width: 1.3125rem;
  height: 0.875rem;
  color: rgb(var(--p-70));
}

@media (min-width: 64rem) {.e_dd {
    gap: 0.625rem
}

.e_ed {
    font-size: 1.5rem;
    line-height: 2rem
}

.e_fd {
    margin: 0
}

.e_gd {
    font-size: 1.375rem;
    font-weight: 400
}
}

@media (min-width: 80rem) {
    .e_gd.e_hd {
      display: block;
      position: relative;
    }

.e_id {
    position: absolute;
    left: -1.75rem;
    top: 0.3125rem
}
}

.e_u {
  margin-top: 0.9375rem;
}

.e_v {
  margin-top: 1.875rem;
}

/* min-height: rem(142); */

.e_w {
  margin-bottom: 0.625rem;
}

.e_x {
  margin: 1.25rem 0;
}

.e_y {
  margin-top: 1.875rem;
}

.e_z {
  position: sticky;
  top: 7.5rem;
}

@media (min-width: 40rem) {

.e_v {
    contain: none !important;
    overflow: visible !important;
    margin-bottom: 1.875rem;
    padding-bottom: 1.875rem
}

.e_y {
    margin-top: 2.5rem
}
}

@media (min-width: 64rem) {

.e_w {
    margin-bottom: 0.9375rem
}
}

@media (min-width: 80rem) {

.e_z {
    top: 8.75rem
}
}

.e_A {
  margin: 0.75rem 0;
}

.e_B {
  display: none;
}

@media (min-width: 64rem) {

.e_B {
    display: block
}

.e_C {
    margin-top: 1.25rem
}
}


.e_Pc {
  contain: content;
  display: grid;
  grid-row-gap: 0.9375rem;
  row-gap: 0.9375rem;
}

.e_Qc {
  background-color: transparent;
}

@media (min-width: 48rem) {.e_Pc {
    row-gap: 1.25rem
}
}

.e_D {
  margin: 0.9375rem 0 0.625rem 0;
}@media (min-width: 48rem) {.e_D {
    margin-top: 1.5625rem
}
}

